html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}

@media print {
  /* Add border to each page */
  @page {
    margin: 0.2in; /* Adjust margins as necessary */
    border: 1px solid black; /* Border for each page */
  }

  /* Ensure the content doesn't spill over */
  .content {
    page-break-before: always;
    page-break-after: always;
  }

  /* Optional: You can also add borders around the content itself */
  .content {
    border: 2px solid #000;
    padding: 20px;
  }

  /* Hide the print button when printing */
  button {
    display: none;
  }
}
.bus_seat_plan_table td,
.bus_seat_plan_table th {
  padding: 8px 13px;
}

.pay-buttons-right {
  display: flex;
  justify-content: center;
  gap: 50px;
}

@media (max-width: 992px) {
  .payment-buttons-style {
    max-width: 500px !important;
  }
}
@media (max-width: 556px) {
  .payment-buttons-style {
    max-width: 300px !important;
  }
}

@media (max-width: 400px) {
  .payment-buttons-style {
    max-width: 250px !important;
  }
}
